<template>
  <div class="header">
    <div class="content">
      <div class="logo" @click="openLink">
        <img :src="logoObj.logo" alt="logo" v-if="logoObj.type === 1">
        <h1 v-else>{{logoObj.logo}}</h1>
      </div>
      <div class="right" v-if="phone">
        <p>全国统一购课热线</p>
        <div class="phone">
          <img src="https://img2.233.com/wx/union/pc/home/blue/phone-icon.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/phone-icon.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/phone-icon.png" v-else>
          <span>{{phone}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    phone: {
      type: String,
      default: ""
    },
    logoObj: {
      type: Object,
      default: () => {}
    },
    loginStatus: {
      type: Object,
      default: () => {}
    },
    skinColor: {
      type: String,
      default: ""
    }
  },
  methods: {
    openLink() {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/index.scss";
.header{
  background: white;
  .content{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 83px;
    .logo{
      height: 64px;
      line-height: 64px;
      cursor: pointer;
      img{
        height: 64px;
        vertical-align: top;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      p{
        color: #9A9A9A;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
      }
      .phone{
        display: flex;
        align-items: center;
        img{
          width: 28px;
          height: 28px;
        }
        span{
          @include globe_color();
          font-size: 22px;
          font-weight: 400;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>