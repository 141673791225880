<template>
  <div class="home">
    <anav :loginStatus="loginStatus" />
    <aheader
      :phone="phone"
      :logoObj="logoObj"
      :loginStatus="loginStatus"
      :skinColor="skinColor"
    />
    <vanguard
      :unionClassTree="unionClassTree"
      :skinColor="skinColor"
      :chains="chains"
      :banners="banners"
      :loginStatus="loginStatus"
      :orderAndCouponNum="orderAndCouponNum"
      :couponControl="couponControl"
      v-if="banners.length > 0"
    />
    <div class="bg1">
      <advantage :skinColor="skinColor" />
      <atitle :title="'课程推荐'" />
      <course
        :seniorFlog="seniorFlog"
        :unionClassTree="unionClassTree"
        :advStatus="advStatus"
      />
      <atitle :title="'直播预告'" v-if="lives.length > 0" />
      <live :lives="lives" v-if="lives.length > 0" />
      <adv
        v-if="
          advStatus.flag === 1 || advStatus.flag === 2 || advStatus.flag === 3
        "
        :advStatus="advStatus"
      />
    </div>
    <div class="bg23">
      <atitle :title="'网校老师'" v-if="teacherList.length > 0" class="white" />
      <teacher
        :skinColor="skinColor"
        :teacherList="teacherList"
        v-if="teacherList.length > 0"
      />
      <atitle :title="'学习服务'" />
      <serve :skinColor="skinColor" />
      <afooter :footerList="footerList" />
      <img
        src="https://img2.233.com/wx/union/pc/home/blue-bg.jpg"
        v-show="teacherList.length > 0"
        class="bg2"
      />
      <img
        src="https://img2.233.com/wx/union/pc/home/down-bg.jpg"
        class="bg3"
        :style="!teacherList.length ? 'top:0' : ''"
      />
    </div>
    <rightFixed />
  </div>
</template>

<script>
import { GetSeniorFlag, GetSkinColorByHost, GetLoginStatus, GetUnionLogoByHost, GetPhoneByHost, GetHeaderByHost, GetBannerByHost, /** GetOrderCountAndCouponCount, */
  GetUnionClass, GetHomePageGetCouponByHost, GetTeacherListByHost, GetFooterByHost, GetUnionPermission } from "../../lib/request"
import anav from "./cell/nav"
import aheader from "./cell/header"
import vanguard from "./cell/vanguard"
import advantage from "./cell/advantage"
import atitle from "./cell/title"
import course from "./cell/course"
import live from "./cell/live"
import adv from "./cell/adv"
import teacher from "./cell/teacher"
import serve from "./cell/serve"
import afooter from "./cell/footer"
import rightFixed from "../../components/rightFixed/rightFixed"
export default {
  name: "Home",
  components: {
    anav,
    aheader,
    vanguard,
    advantage,
    atitle,
    course,
    live,
    adv,
    teacher,
    serve,
    afooter,
    rightFixed
  },
  data() {
    return {
      phone: "",
      logoObj: {},
      loginStatus: {},
      skinColor: "red",
      seniorFlog: false,

      chains: [],
      banners: [],
      orderAndCouponNum: {},
      couponControl: {},

      unionClassTree: [],

      liveList: [],
      advStatus: {},
      teacherList: [],

      footerList: [],
      cnzzKey: ""
    }
  },
  computed: {
    lives() {
      // const _arr = this.liveList.filter(item => (item.Status === 1 || item.Status === 2) && !item.IsFree)
      const _arr = this.liveList
      return _arr
    }
  },
  mounted() {
    this.GetSeniorFlag()
    this.GetSkinColorByHost()
    this.GetLoginStatus()
    this.GetUnionLogoByHost()
    this.GetPhoneByHost()

    this.GetHeaderByHost()
    this.GetBannerByHost()

    this.GetUnionClass()
    this.getlive()
    this.GetHomePageGetCouponByHost()
    this.GetTeacherListByHost()
    this.GetFooterByHost()
  },
  methods: {
    GetSeniorFlag() {
      GetSeniorFlag({ host: location.host }, (res) => {
        this.seniorFlog = res.Result
        if (res.Result) {
          location.href.indexOf('/norm') && (location.href = location.href.replace('/norm', '/fine'))
        } else {
          location.href.indexOf('/fine') && (location.href = location.href.replace('/fine', '/norm'))
        }
        const _nu = navigator.userAgent
        if ((/AppleWebKit.*Mobile/i.test(_nu) || /Android|Windows Phone|webOS|iPhone|iPod|BlackBerry/i.test(_nu) || /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(_nu)) && !/iPad/i.test(_nu) && location.hash != '#m') {
          if (res.Result) {
            window.location.href = window.location.origin + "/m/fine"
          } else {
            window.location.href = window.location.origin + "/m/norm/new/#/"
          }
        }
      })
    },
    GetSkinColorByHost() {
      GetSkinColorByHost({}, (res) => {
        const { Result } = res
        window.document.documentElement.setAttribute('data-theme', Result.color)
        this.skinColor = Result.color
      })
    },
    GetLoginStatus() {
      GetLoginStatus({}, (res) => {
        const { Result } = res
        this.loginStatus = Result
        document.title = "首页-" + Result.webName
        Result.status && this.GetOrderCountAndCouponCount()
      })
    },
    GetUnionLogoByHost() {
      GetUnionLogoByHost({}, (res) => {
        this.logoObj = res.Result
      })
    },
    GetPhoneByHost() {
      GetPhoneByHost({}, (res) => {
        this.phone = res.Result
      })
    },
    GetHeaderByHost() {
      GetHeaderByHost({}, (res) => {
        this.chains = res.Result
      })
    },
    GetBannerByHost() {
      GetBannerByHost({}, (res) => {
        this.banners = res.Result
      })
    },
    GetOrderCountAndCouponCount() {
      // GetOrderCountAndCouponCount({}, (res) => {
      //   this.orderAndCouponNum = res.Result
      //   this.GetUnionPermission()
      // })
    },
    GetUnionPermission() {
      GetUnionPermission({}, (res) => {
        this.couponControl = res.Result
      })
    },
    GetUnionClass() {
      GetUnionClass({}, (res) => {
        let _arr = []
        res.Result.forEach(element => {
          element.ParentId === 0 && _arr.push({ ...element, childData: [] })
        })
        _arr.forEach(item => {
          res.Result.forEach(element => {
            item.ClassId === element.ParentId && item.childData.push(element)
          })
        })
        let _arr2 = []
        _arr.forEach(item => {
          if (item.childData.length > 0) {
            _arr2.push(item)
          }
        })
        this.unionClassTree = _arr2
      })
    },
    async getlive() {
      // getlive({ Status: 1, host: "wx.029985.com" }, (res) => {
     const { data = [] } = await this.$api.getLiveReport({ isFree: 2 });
      this.liveList = data;
    },
    GetHomePageGetCouponByHost() {
      GetHomePageGetCouponByHost({}, (res) => {
        this.advStatus = res.Result
      })
    },
    GetTeacherListByHost() {
      GetTeacherListByHost({}, (res) => {
        this.teacherList = res?.Result || []
      })
    },
    GetFooterByHost() {
      GetFooterByHost({}, (res) => {
        this.footerList = res.Result
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.home {
  .bg1 {
    background: url(https://img2.233.com/wx/union/pc/home/center-bg.jpg)
      no-repeat bottom center;
  }
  .bg23 {
    position: relative;
    padding-top: 20px;
    .white {
      color: white;
    }
    .bg2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 625px;
      z-index: -1;
    }
    .bg3 {
      position: absolute;
      top: 625px;
      left: 0;
      width: 100%;
      z-index: -1;
    }
  }
}
</style>
