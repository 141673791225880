<template>
  <div class="live">
    <swiper :options="swiperOptions">
      <swiper-slide v-for="item in lives.slice(0, 12)" :key="item.Id">
        <div class="box">
          <div class="resume">
            <div class="img-box">
              <img
                :src="item.teacherPic || defaultTeacherImage"
                @error="
                  (e) => {
                    e.target.src = defaultTeacherImage;
                  }
                "
                alt=""
              />
            </div>
            <div class="info">
              <p>{{ item.title }}</p>
              <span>时间:{{ item.liveDateStr }} | {{ item.teacherName }}</span>
            </div>
          </div>
          <a target="_blank" href="javascript:;" @click="handler2Live(item)">{{
            liveStatusText(item)
          }}</a>
        </div>
      </swiper-slide>
      <div
        class="swiper-button-prev"
        slot="button-prev"
        v-if="lives.length > 3"
      ></div>
      <div
        class="swiper-button-next"
        slot="button-next"
        v-if="lives.length > 3"
      ></div>
    </swiper>
  </div>
</template>

<script>
import { objToStr } from "@/utils";
export default {
  name: "Live",
  props: {
    lives: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptions: {
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 32,
      },
      defaultTeacherImage:
        "https://img2.233.com/zb/images/default_teacher_pic.png",
    };
  },
  methods: {
    handler2Live(item) {
      window.open(
        window.location.origin +
          `/m/zhibo/#/?${objToStr({
            domain: item.domain,
            status: 2, // 付费的
          })}`
      );
    },
    liveStatusText(item) {
      let text = "已结束";
      switch (item.liveState) {
        case 1:
          text = "已预约";
          break;
        case 2:
          text = "立即预约";
          break;
        case 4:
          text = "直播中";
          break;
        case 9:
          // text = "去回看";
          break;
        case 8:
          // text = "回看上传中";
          break;
        case 7:
          // text = "";
          break;

        default:
          break;
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/index.scss";
.live {
  width: 1200px;
  margin: 0 auto 45px;
  .box {
    width: 380px;
    height: 206px;
    background: #fff;
    box-shadow: -1px 16px 34px 4px rgba(180, 180, 180, 0.18);
    padding: 37px 32px 0;
    box-sizing: border-box;
    .resume {
      display: flex;
      align-items: center;
      .img-box {
        margin-right: 20px;
        overflow: hidden;
        width: 70px;
        height: 70px;
        background: #f2f4f3;
        border-radius: 50%;
        position: relative;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .info {
        flex: 1;
        p {
          color: #333;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 12px;
          height: 48px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        span {
          color: #999;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          width: 226px;
          height: 20px;
        }
      }
    }
    a {
      width: 100%;
      height: 48px;
      background: white;
      @include live_border();
      font-size: 18px;
      @include globe_color();
      line-height: 46px;
      border-radius: 24px;
      font-weight: 400;
      margin-top: 20px;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        transform: translateY(-3px);
        @include globe_button_hover();
        @include globe_gradient();
        border: none;
        line-height: 48px;
      }
    }
  }
  .swiper-button-prev {
    background: url(https://img2.233.com/wx/union/pc/home/left-icon.png)
      no-repeat center center;
    background-size: 6px 11px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 31px;
    height: 67px;
    margin-top: -31px;
    left: 0;
    &::after {
      content: "";
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .swiper-button-next {
    background: url(https://img2.233.com/wx/union/pc/home/right-icon.png)
      no-repeat center center;
    background-size: 6px 11px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 31px;
    height: 67px;
    margin-top: -31px;
    right: 0;
    &::after {
      content: "";
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
