<template>
  <div class="serve">
    <div class="one">
      <div class="box">
        <img src="https://img2.233.com/wx/union/pc/home/blue/pencil-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/pencil-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/pencil-icon.png" v-else>
        <h4>免费做题</h4>
        <p>光听不做怎么行？历年真题/模拟考场/每日一练/章节练习等你来战</p>
      </div>
      <div class="box">
        <img src="https://img2.233.com/wx/union/pc/home/blue/relearn-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/relearn-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/relearn-icon.png" v-else>
        <h4>重学服务</h4>
        <p>购买设有重学保障服务班级，可享受考试不过免费重学服务</p>
      </div>
      <div class="box">
        <img src="https://img2.233.com/wx/union/pc/home/blue/answering-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/answering-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/answering-icon.png" v-else>
        <h4>师资答疑</h4>
        <p>学习过程有疑问？<br>专业师资24小时内为您在线解答</p>
      </div>
      <div class="box">
        <img src="https://img2.233.com/wx/union/pc/home/blue/study-plan-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/study-plan-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/study-plan-icon.png" v-else>
        <h4>学习计划</h4>
        <p>科学教学规划，采用渐进式教学体系，让学习层层递进</p>
      </div>
    </div>
    <div class="two">
      <div class="box">
        <div class="left">
          <img src="https://img2.233.com/wx/union/pc/home/blue/hd-icon.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/hd-icon.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/hd-icon.png" v-else>
        </div>
        <div class="right">
          <p>高清画质</p>
          <p>舒畅学习</p>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <img src="https://img2.233.com/wx/union/pc/home/blue/quality-icon.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/quality-icon.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/quality-icon.png" v-else>
        </div>
        <div class="right">
          <p>知名老师授课</p>
          <p>畅享品质学习</p>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <img src="https://img2.233.com/wx/union/pc/home/blue/hltk-icon.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/hltk-icon.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/hltk-icon.png" v-else>
        </div>
        <div class="right">
          <p>高质量考试同步题库</p>
          <p>考前点题、模拟试题</p>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <img src="https://img2.233.com/wx/union/pc/home/blue/sweet-icon.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/sweet-icon.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/sweet-icon.png" v-else>
        </div>
        <div class="right">
          <p>为考生提供多方位服务</p>
          <p>在线答疑、学习资料</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advantage",
  props: {
    skinColor: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/index.scss";
.serve{
  width: 1200px;
  margin: 0 auto;
  .one{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box{
      padding: 36px 50px 40px;
      text-align: center;
      width: 279px;
      box-sizing: border-box;
      background: url(https://img2.233.com/wx/union/pc/home/color-bg.png) no-repeat top center;
      background-color: #fff;
      box-shadow: -1px 9px 27px 3px rgba(180, 180, 180, 0.27);
      border-bottom: 5px solid #E13C2A;
      @include serve_border_bottom();
      transition: .2s;
      &:hover{
        transform: translateY(-3px);
      }
      &:nth-child(2) img{
        margin: 4px 0;
      }
      &:nth-child(3) img{
        margin: .5px 0;
      }
      &:nth-child(4) img{
        margin: 3px 0;
      }
      h4{
        line-height: 72px;
        color: #343434;
        font-size: 26px;
        font-weight: 400;
      }
      p{
        color: #666;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }
  .two{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 35px 40px 32px;
    margin-top: 45px;
    box-shadow: 0px 9px 35px 4px rgba(180, 180, 180, 0.18);
    .box{
      display: flex;
      align-items: center;
      margin-right: 60px;
      padding-right: 60px;
      border-right: 1px solid #EBEBEB;
      &:nth-last-child(1) {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
      img{
        width: 60px;
        height: 60px;
        margin-right: 16px;
      }
      p{
        color: #565656;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}
</style>