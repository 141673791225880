<template>
  <div class="footer">
    <div class="content">
      <p v-if="menu.length > 0" class="menu">
        <template v-for="(item, index) in menu">
          <span :key="index" @click="openLink(item.LinkUrl)">{{item.Contents}}</span>
          <label v-if="index + 1 < menu.length" :key="index + 100"></label>
        </template>
      </p>
      <p>
        <template v-if="hotLink.Contents">
          <span>24小时报名热线：{{hotLink.Contents}}</span>
          <label></label>
        </template>
        <template v-if="qq.Contents">
          <span>QQ：{{qq.Contents}}</span>
          <label></label>
        </template>
        <template v-if="email.Contents">
          <span>邮箱：{{email.Contents}}</span>
          <label></label>
        </template>
        <template v-if="address.Contents">
          <span>地址：{{address.Contents}}</span>
        </template>
      </p>
      <p>
        <span @click="openLink(putonrecord.LinkUrl)">{{putonrecord.Contents}}</span>
        <template v-if="putonrecord.Contents && gonganbeian.Contents">
          <label></label>
        </template>
        <img v-if="gonganbeian.Contents" src="https://img2.233.com/wx/union/pc/home/bottom_ic.png">
        <span @click="openLink(gonganbeian.LinkUrl)">{{gonganbeian.Contents}}</span>
      </p>
      <p>
        <span @click="openLink(gongshang.LinkUrl)">{{gongshang.Contents}}</span>
        <template v-if="gongshang.Contents && copyright.Contents">
          <label></label>
        </template>
        <span @click="openLink(copyright.LinkUrl)">{{copyright.Contents}}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    footerList: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    hotLink() {
      return this.footerList.find(item => item.Type === "phone") || {}
    },
    qq() {
      return this.footerList.find(item => item.Type === "qq") || {}
    },
    email() {
      return this.footerList.find(item => item.Type === "email") || {}
    },
    address() {
      return this.footerList.find(item => item.Type === "address") || {}
    },
    copyright() {
      return this.footerList.find(item => item.Type === "copyright") || {}
    },
    putonrecord() {
      return this.footerList.find(item => item.Type === "putonrecord") || {}
    },
    gonganbeian() {
      return this.footerList.find(item => item.Type === "gonganbeian") || {}
    },
    menu() {
      return this.footerList.filter(item => item.Type === "menu") || []
    },
    gongshang() {
      return this.footerList.find(item => item.Type === "gongshang") || {}
    }
  },
  methods: {
    openLink(v) {
      window.open(v ? v : "https://beian.miit.gov.cn/")
    }
  }
}
</script>

<style>
.home .footer .content p span a{
  color: #9A9A9A;
}
</style>

<style lang="scss" scoped>
.footer{
  width: 100%;
  text-align: center;
  .content{
    margin: 0 auto;
    width: 1200px;
    padding: 31px 0 52px;
    p{
      line-height: 26px;
      &:nth-child(1), &:nth-child(3), &:nth-child(4) {
        span{
          cursor: pointer;
        }
      }
      span{
        color: #9F9F9F;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
      }
      label{
        display: inline-block;
        width: 1px;
        height: 14px;
        background: #9F9F9F;
        margin: 0 10px;
        vertical-align: middle;
      }
      img{
        margin-right: 6px;
        vertical-align: middle;
      }
    }
    .menu span{
      color: #565656;
      font-size: 16px;
    }
  }
}
</style>