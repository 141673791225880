<template>
  <div
    class="rightFixed"
    v-show="rightFixedShow"
    v-if="rightNavData.KefuType === 0 && (rightNavData.KefuQQList.length || rightNavData.KefuTelList.length || rightNavData.UnionWeixin)"
  >
    <div class="qq-kefuBox" v-if="rightNavData.KefuQQList.length">
      <h4 class="rightFixed-h4">QQ客服</h4>
      <div class="qq-kefuCont">
        <a :href="'http://wpa.qq.com/msgrd?v=3&amp;uin=' + qqKeFu.KefuNumber + '&amp;site=qq&amp;menu=yes'" v-for="(qqKeFu,index) in rightNavData.KefuQQList" :key="index" target="_blank">
        <span class="qq-ico"></span>{{ qqKeFu.KefuName ? qqKeFu.KefuName : ('客服' + kefuNum[index])}}</a>
      </div>
    </div>
    <div class="dh-kefuBox" v-if="rightNavData.KefuTelList.length">
      <h4 class="rightFixed-h4">电话客服</h4>
      <div class="dh-kefuCont">
        <p v-for="(phone, index) in rightNavData.KefuTelList" :key="index">
          {{ phone.KefuNumber }}
        </p>
      </div>
    </div>
    <div class="wx-kefuBox" v-if="rightNavData.UnionWeixin">
      <h4 class="rightFixed-h4">微信客服</h4>
      <div class="wx-kefuCont">
        <p><img :src="rightNavData.UnionWeixin" alt="" /></p>
      </div>
    </div>
    <div class="back-top" @click="toTop">TOP <span class="jt"></span></div>
    <span class="rightFixed-close" @click="rightFixedShow = false"></span>
  </div>
</template>
<script>
import { GetKeFuByHost } from "../../lib/request"

export default {
  name: "RightFixed",
  data() {
    return {
      /**右侧导航显示 */
      rightFixedShow: true,
      /**右侧客服导航数据 */
      rightNavData: {
        KefuCode: "",
        KefuType: 0, //客服类型  0: 电话+QQ  1: CC客服  2:53客服  3:百度商桥  4:乐语客服  5:企业QQ
        KefuQQList: [],
        KefuTelList: [],
        UnionWeixin: "",
      },
      kefuNum: ['①','②','③','④','⑤','⑥','⑦','⑧','⑨','⑩']
    }
  },
  created() {
    this.getKeFuNav()
  },
  methods: {
    /**获取客服导航数据 */
    getKeFuNav() {
      GetKeFuByHost({}, (res) => {
        if (res.ResultDescription) return
        this.rightNavData = res.Result

        /**客服类型不为0时动态加载对应的js */
        if (this.rightNavData.KefuType !== 0) {
          this.keFuType(this.rightNavData.KefuType, this.rightNavData.KefuCode)
        }
      })
    },
    /**返回顶部 */
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
    /**去联系客服 */
    toQqKeFu(qq) {
      window.location.href = `http://wpa.qq.com/msgrd?v=3&amp;uin=${qq.KefuNumber}&amp;site=qq&amp;menu=yes`
    },
    /**客服类型 */
    keFuType(type, code) {
      var jsUrl = ""
      switch (type) {
        case 1:
          //CC客服
          jsUrl = `http://kefu.ziyun.com.cn/vclient/?webid=${encodeURIComponent(code)}`
          break
        case 2:
          //53客服
          jsUrl = `https://tb.53kf.com/code/code/${code}`
          break
        case 3:
          //百度
          jsUrl = `http://hm.baidu.com/hm.js?${code}`
          break
        case 4:
          //乐语
          jsUrl = `http://lead.soperson.com/${code}.js`
          break
        case 5:
          //企业QQ
          jsUrl = `http://wpa.b.qq.com/cgi/wpa.php?key=${code}`
          break
      }
      this.createJs(jsUrl)
    },
    /**动态生成js */
    createJs(url) {
      let script = document.createElement("script")
      script.type = "text/javascript"
      script.src = url
      document.getElementsByTagName("head")[0].appendChild(script)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/index.scss";
i, em {
  font-style: normal;
}
ol, ul {
  list-style: none;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  color: #333;
}

.rightFixed {
  position: fixed;
  top: 50%;
  // left: 50%;
  right: 1%;
  // margin-left: 640px;
  transform: translateY(-50%);
  width: 120px;
  padding-top: 1px;
  background-color: #fff;
  box-shadow: 0 5px 26px rgba(163, 163, 163, 0.2);
  // z-index: 9;
  z-index: 12;
}
.rightFixed .rightFixed-h4 {
  height: 34px;
  line-height: 34px;
  text-align: center;
  margin: 14px 14px 0;
  border-bottom: 1px solid #f4f4f4;
  font-size: 15px;
  color: #333333;
  font-weight: 900;
}
.rightFixed .qq-kefuCont > a {
  display: block;
  height: 17px;
  line-height: 17px;
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 13px;
}
.rightFixed .qq-kefuCont > a:hover {
  color: #5d6fd3;
}
.rightFixed .qq-kefuCont .qq-ico {
  display: inline-block;
  width: 25px;
  height: 17px;
  background: url(https://img.233.com/www/gongyong/ico-rightFixed.png) no-repeat -20px
    0;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
}
.rightFixed .dh-kefuCont > p {
  text-align: center;
  font-size: 13px;
  color: #666;
  margin-top: 8px;
}
.rightFixed .wx-kefuCont > p {
  width: 84px;
  height: 84px;
  margin: 8px auto 0;
}
.rightFixed .wx-kefuCont > p img {
  display: block;
  width: 100%;
  height: 100%;
}
.rightFixed .back-top {
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 14px;
  font-size: 14px;
  color: #fff;
  @include globe_gradient();
  cursor: pointer;
}
.rightFixed .back-top .jt {
  display: inline-block;
  width: 13px;
  height: 9px;
  background: url(https://img.233.com/www/gongyong/ico-rightFixed.png) no-repeat -60px
    0;
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 4px;
}
.rightFixed-close {
  position: absolute;
  top: 3px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: url(https://img.233.com/www/gongyong/ico-rightFixed.png) no-repeat
    5px 5px;
  transition: 0.4s;
  cursor: pointer;
}
.rightFixed-close:hover {
  transform: rotate(180deg);
}
</style>