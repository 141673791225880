<template>
  <div class="vanguard">
    <div class="chain-bg">
      <div class="chain">
        <div class="subject">
          <img src="https://img2.233.com/wx/union/pc/home/all-icon.png">全部考试科目分类
        </div>
        <a href="/" class="home">首页</a>
        <a target="_blank" :href="item.LinkUrl" v-for="item in chains" :key="item.ID" :title="item.Title">{{item.Title.slice(0, 6)}}</a>
      </div>
    </div>
    <div class="banner" @mouseover="enter" @mouseleave="leave">
      <swiper :options="swiperOptions" ref="banner">
        <swiper-slide v-for="item in banners" :key="item.Id">
          <a target="_blank" :href="item.Href" v-if="item.Href">
            <img :src="item.ImgUrl">
          </a>
          <a v-else>
            <img :src="item.ImgUrl">
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" v-if="banners.length > 1"></div>
        <div class="swiper-button-prev" slot="button-prev" v-if="banners.length > 1"></div>
        <div class="swiper-button-next" slot="button-next" v-if="banners.length > 1"></div>
      </swiper>
    </div>
    <ul :class="['itemize', unionClassTree.length > 2 ? 'has-more' : '']">
      <li v-for="item in unionClassTree.slice(0, 3)" :key="item.ClassId">
        <p>{{item.ClassName}}</p>
        <div class="labels">
          <template v-if="unionClassTree.length > 2">
            <a v-for="ii in item.childData.slice(0, 6)" :key="ii.ClassId" :href="ii.Href" target="_blank">{{ii.ClassName}}</a>
          </template>
          <template v-else>
            <a v-for="ii in item.childData" :key="ii.ClassId" :href="ii.Href" target="_blank">{{ii.ClassName}}</a>
          </template>
        </div>
      </li>
      <li v-if="unionClassTree.length > 2">
        <p>查看更多<img src="https://img2.233.com/wx/union/pc/home/right-but.png"></p>
      </li>
      <div class="more">
        <div class="box" v-for="item in unionClassTree" :key="item.ClassId">
          <span>{{item.ClassName}}</span>
          <div class="labels">
            <a v-for="ii in item.childData" :key="ii.ClassId" :href="ii.Href" target="_blank">{{ii.ClassName}}</a>
          </div>
        </div>
      </div>
    </ul>
    <div class="login">
      <div class="info" v-if="loginStatus.status">
        <img :src="loginStatus.picPath">
        <h4>{{loginStatus.userName}}</h4>
        <p>欢迎来到{{loginStatus.webName}}！</p>
      </div>
      <div class="info" v-else>
        <img src="https://img2.233.com/wx/union/pc/home/default-header.png">
        <h4>欢迎来到{{loginStatus.webName}}！</h4>
      </div>
      <ul class="other" v-if="loginStatus.status">
        <li @click="openLink(3)">
          <img src="https://img2.233.com/wx/union/pc/home/blue/chain-icon1.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/chain-icon1.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/chain-icon1.png" v-else>
          <p>购物车</p>
          <!-- <span v-if="orderAndCouponNum.eCartCount > 0">{{orderAndCouponNum.eCartCount}}</span> -->
        </li>
        <li @click="openLink(4)" v-if="couponControl.UActivityFlag !== 0">
          <img src="https://img2.233.com/wx/union/pc/home/blue/chain-icon2.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/chain-icon2.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/chain-icon2.png" v-else>
          <p>优惠券</p>
          <!-- <span v-if="orderAndCouponNum.CouponCount > 0">{{orderAndCouponNum.CouponCount}}</span> -->
        </li>
        <li @click="openLink(5)">
          <img src="https://img2.233.com/wx/union/pc/home/blue/chain-icon3.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/chain-icon3.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/chain-icon3.png" v-else>
          <p>课程</p>
        </li>
        <li @click="openLink(6)">
          <img src="https://img2.233.com/wx/union/pc/home/blue/chain-icon4.png" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/chain-icon4.png" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/chain-icon4.png" v-else>
          <p>题库</p>
        </li>
      </ul>
      <div class="fun" v-else>
        <span @click="openLink(1)">学员登录</span>
        <span @click="openLink(2)">学员注册</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vanguard",
  props: {
    unionClassTree: {
      type: Array,
      default: () => []
    },
    chains: {
      type: Array,
      default: () => []
    },
    banners: {
      type: Array,
      default: () => []
    },
    loginStatus: {
      type: Object,
      default: () => {}
    },
    orderAndCouponNum: {
      type: Object,
      default: () => {}
    },
    couponControl: {
      type: Object,
      default: () => {}
    },
    skinColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      swiperOptions: {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        autoplay: 3000,
        autoplayDisableOnInteraction : false
      }
    }
  },
  mounted() {
    if (this.banners.length === 0) {
      this.$refs.banner.swiper.stopAutoplay()
    }
  },
  methods: {
    enter() {
      this.$refs.banner.swiper.stopAutoplay()
    },
    leave() {
      this.$refs.banner.swiper.startAutoplay()
    },
    openLink(type) {
      switch (type) {
        case 1:
          window.location.href = window.location.origin + "/uc/login?redirecturl=" + window.location.href
          break;
        case 2:
          window.open(window.location.origin + "/uc/register")
          break;
        case 3:
          window.open(window.location.origin + "/order/settle")
          break;
        case 4:
          window.open(window.location.origin + "/center/order/wallet?type=coupon")
          break;
        case 5:
          window.open(window.location.origin + "/center")
          break;
        case 6:
          window.open(window.location.origin + "/center/study/tiku")
          break;
        default:
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/index.scss";
.vanguard{
  position: relative;
  .chain-bg{
    width: 100%;
    height: 57px;
    border-top: 1px solid #E2E2E2;
    background: white;
    .chain{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      height: 57px;
      line-height: 57px;
      overflow: hidden;
      .subject{
        width: 305px;
        @include globe_gradient();
        font-size: 18px;
        color: #fff;
        font-weight: 400;
        img{
          width: 24px;
          height: 14px;
          margin: 0 18px 0 23px;
        }
      }
      a{
        padding: 0 40px;
        color: #343434;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        display:inline-block;
        &:hover{
          @include globe_color();
        }
      }
      .home{
        @include globe_color();
        margin-left: 20px;
      }
    }
  }
  .banner{
    a{
      overflow: hidden;
      text-align: center;
      width: 100%;
      height: 370px;
      display: flex;
      justify-content: center;
      img{
        width: 1920px;
        height: 370px;
      }
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
      bottom: 20px;
    }
    /deep/.swiper-pagination{
      .swiper-pagination-bullet{
        background: #fff;
        opacity: .25;
      }
      .swiper-pagination-bullet-active{
        opacity: 1;
        width: 28px;
        border-radius: 4px;
      }
    }
    .swiper-button-prev{
      background: url(https://img2.233.com/wx/union/pc/home/left-icon.png) no-repeat center center;
      background-size: 6px 11px;
      background-color: rgba(0, 0, 0, .3);
      width: 21px;
      height: 42px;
      margin-top: -21px;
      left: calc(50% - 295px);
      &::after{
        content: "";
      }
      &:hover{
        background-color: rgba(0, 0, 0, .5);
      }
    }
    .swiper-button-next{
      background: url(https://img2.233.com/wx/union/pc/home/right-icon.png) no-repeat center center;
      background-size: 6px 11px;
      background-color: rgba(0, 0, 0, .3);
      width: 21px;
      height: 42px;
      margin-top: -21px;
      right: calc(50% - 350px);
      &::after{
        content: "";
      }
      &:hover{
        background-color: rgba(0, 0, 0, .5);
      }
    }
  }
  .itemize{
    position: absolute;
    top: 58px;
    left: calc(50% - 600px);
    width: 305px;
    height: 370px;
    padding: 5px 18px 0;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 11;
    li{
      list-style: none;
      border-bottom: 1px dashed #EAEAEA;
      padding-bottom: 13.5px;
      &:nth-last-child(1){
        border-bottom: none;
      }
      p{
        font-size: 16px;
        color: #333;
        font-weight: bold;
        line-height: 31px;
        margin-top: 8.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .labels{
        a{
          display: inline-block;
          color: #666;
          font-size: 14px;
          font-weight: 400;
          margin-right: 20px;
          line-height: 25px;
          cursor: pointer;
          width: 72px;
          height: 25px;
          overflow: hidden;
          vertical-align: top;
          text-decoration: none;
          &:hover{
            @include globe_color();
          }
          &:nth-child(3n){
            margin-right: 0;
          }
        }
      }
    }
    .more{
      position: absolute;
      top: 0;
      left: 305px;
      transition: .2s;
      width: 0;
      min-height: 370px;
      height: 370px;
      box-sizing: border-box;
      background: #fff;
      box-shadow: -1px 16px 35px 4px rgba(180, 180, 180, 0.18);
      overflow: hidden;
      .box{
        visibility: hidden;
        display: flex;
        padding: 15px 0;
        border-bottom: 1px dashed #EAEAEA;
        &:nth-last-child(1){
          border-bottom: none;
        }
        span{
          color: #343434;
          font-size: 16px;
          font-weight: bold;
          display: inline-block;
          width: 92px;
          overflow: hidden;
          height: 22px;
          line-height: 22px;
        }
        .labels{
          flex: 1;
          a{
            display: inline-block;
            font-weight: 400;
            cursor: pointer;
            color: #666;
            font-size: 14px;
            padding: 0 13px;
            line-height: 22px;
            text-decoration: none;
            &:hover{
              @include globe_color();
            }
          }
        }
      }
    }
  }
  .has-more{
    &:hover .more{
      width: 895px;
      padding: 0 28px;
      height: auto;
      .box{
        visibility: inherit;
        visibility: unset;
      }
    }
  }
  .login{
    position: absolute;
    top: 104px;
    right: calc(50% - 600px);
    width: 250px;
    height: 280px;
    padding: 29px 18px;
    box-sizing: border-box;
    background-color: #EAEAEA;
    background: url(https://img2.233.com/wx/union/pc/home/login-bg.png) no-repeat center center;
    background-size: 100% 100%;
    border-radius: 10px;
    z-index: 10;
    .info{
      text-align: center;
      img{
        width: 66px;
        height: 66px;
        border-radius: 33px;
        vertical-align: top;
      }
      h4, p{
        color: #333;
        font-size: 15px;
        font-weight: 400;
        margin-top: 12px;
        line-height: 17px;
      }
      h4{
        margin-top: 14px;
      }
    }
    .fun{
      text-align: center;
      span{
        width: 160px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        font-size: 15px;
        color: #352640;
        font-weight: 400;
        @include globe_gradient();
        border-radius: 21px;
        display: inline-block;
        margin-top: 25px;
        cursor: pointer;
        color: #fff;
        transition: .2s;
        &:hover{
          transform: translateY(-3px);
        }
        &:nth-child(2){
          @include globe_color();
          @include globe_border();
          background: #FFFFFF;
          border-radius: 21px;
          box-shadow: none;
          margin-top: 15px;
        }
      }
    }
    .other{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 18px;
      border-top: 1px dashed #E6E6E6;
      padding-top: 18px;
      li{
        list-style: none;
        margin: 0 8px;
        text-align: center;
        position: relative;
        cursor: pointer;
        img{
          &:nth-child(1){
            height: 26px;
            margin: 1.5px 0;
          }
          &:nth-child(2){
            height: 25px;
            margin: 2px 0;
          }
          &:nth-child(3){
            height: 28px;
            margin: .5px 0;
          }
          &:nth-child(4){
            height: 29px;
          }
        }
        p{
          margin-top: 10px;
          color: #333;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
        }
        span{
          position: absolute;
          top: -6px;
          right: -10px;
          padding: 0 9px 0 7px;
          height: 17px;
          line-height: 17px;
          @include globe_gradient();
          border-radius: 9px 9px 9px 0px;
          font-size: 14px;
          color: #FFFFFF;
        }
        &:nth-child(2) i{
          right: -4px;
        }
      }
    }
  }
}
</style>