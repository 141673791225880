<template>
  <div class="course">
    <div class="top" v-if="recommend.length > 1">
      <ul>
        <li v-for="item in recommend.slice(0, 6)" :class="item.ClassId === curClass.ClassId ? 'select' : ''" :key="item.ClassId" @click="curClass = item">{{ item.ClassName }}</li>
      </ul>
      <div class="more" @click="openLink(1)">MORE<img src="https://img2.233.com/wx/union/pc/home/more-icon.png" /></div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="not-course" v-if="courseList.length === 0">
          <img src="https://img2.233.com/wx/union/pc/home/default_no_course.png" />
        </div>
        <template v-else>
          <div class="box" v-for="item in courseList.slice(0, 6)" :key="item.MyClassID">
            <div class="class">
              <p>{{ item.MyClassName }}</p>
              <span>{{ item.Validity ? "有效期 : " + item.Validity : item.MonthYXQ ? "有效期 : " + item.MonthYXQ + "个月" : "" }}</span>
              <div class="teacher">
                <div class="item" v-for="items in (item.Teachers || []).slice(0, 3)" :key="items.ID">
                  <div class="img-box">
                    <img :src="items.PicPath || 'https://img2.233.com/boss/course/teacher/20200110-800x800-lq-01.jpg'" onerror='this.src="https://img2.233.com/boss/course/teacher/20200110-800x800-lq-01.jpg"' />
                  </div>
                  <label>{{ items.Name }}</label>
                </div>
              </div>
            </div>
            <div class="fun">
              <div class="price">
                <span v-if="isShowPrice"
                  >¥ <label>{{ item.GoodPrice }}</label></span
                >
                <p>{{ item.BaomingCount }}人在学习</p>
              </div>
              <div class="button" @click="openLink(8)">{{ "免费试听" }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="right">
        <div class="count">
          <p>
            {{ countDown.CountDown === 0 ? "" : "距" }}{{ countDown.ExamYearth ? countDown.ExamYearth + "年" : "" }}<span>{{ curClass.ClassName }}</span
            >考试{{ countDown.CountDown === 0 ? "" : "还有" }}
          </p>
          <div class="unconfirmed" v-if="countDown.CountDown === 0">【暂未确定】</div>
          <div class="num" v-else>
            <label>{{ Math.floor(countDown.CountDown / 100) }}</label>
            <label>{{ Math.floor(countDown.CountDown / 10) % 10 }}</label>
            <label>{{ countDown.CountDown % 10 }}</label>
            <span>天</span>
          </div>
        </div>
        <div class="hot">
          <div class="name"><img src="https://img2.233.com/wx/union/pc/home/hot-icon.png" />热门课程</div>
          <div class="labels">
            <label v-for="item in hotCourse.slice(0, 6)" :key="item.ClassId" @click="openLink(4, item)">{{ item.ClassName }}</label>
          </div>
          <div class="activity">
            <img src="https://img2.233.com/wx/union/pc/home/activity1.png" v-if="advStatus.flag === 1 || advStatus.flag === 3" @click="openLink(2)" style="cursor: pointer;" />
            <img src="https://img2.233.com/wx/union/pc/home/activity2.png" v-if="advStatus.flag === 2" @click="openLink(3)" style="cursor: pointer;" />
            <img src="https://img2.233.com/wx/union/pc/home/activity3.png" v-if="advStatus.flag === 4" />
          </div>
        </div>
        <div class="qrcode">
          <div class="img" v-show="appObj.HaveApp">
            <img src="/search/app/markappqrcode" />
            <p><label>手</label><label>机</label><label>刷</label><label>题</label></p>
          </div>
          <div class="labels">
            <label @click="openLink(7)">模拟考场</label>
            <label @click="openLink(6)">历年真题</label>
            <label @click="openLink(5)">章节练习</label>
            <label @click="openLink(10)">每日一练</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetHomePageCourseByClassID, GetExaminationCountDown, GetClassTopThreeByHost, GetAppQrcode } from "../../../lib/request";
export default {
  name: "Course",
  props: {
    unionClassTree: {
      type: Array,
      default: () => [],
    },
    advStatus: {
      type: Object,
      default: () => {},
    },
    seniorFlog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      curClass: {},
      courseList: [],
      countDown: {
        CountDown: 0,
      },
      hotCourse: [],
      appObj: {},
    };
  },
  computed: {
    recommend() {
      let _arr = [];
      this.unionClassTree.forEach((item) => {
        _arr = [..._arr, ...item.childData];
      });
      return _arr;
    },
    // 是否显示价格
    isShowPrice() {
      return this.$store.getters["common/isShowPrice"];
    },
  },
  watch: {
    unionClassTree() {
      this.curClass = this.recommend[0];
    },
    curClass(v) {
      if (Object.keys(v).length > 0) {
        this.GetHomePageCourseByClassID();
        this.GetExaminationCountDown();
      }
    },
  },
  mounted() {
    this.GetClassTopThreeByHost();
    this.GetAppQrcode();
  },
  methods: {
    GetHomePageCourseByClassID() {
      GetHomePageCourseByClassID({ classID: this.curClass.ClassId }, (res) => {
        this.courseList = res.Result;
      });
    },
    GetExaminationCountDown() {
      GetExaminationCountDown({ classEName: this.curClass.ClassEName }, (res) => {
        this.countDown = res.Result || {};
      });
    },
    GetClassTopThreeByHost() {
      GetClassTopThreeByHost({}, (res) => {
        this.hotCourse = res.Result;
      });
    },
    GetAppQrcode() {
      GetAppQrcode({}, (res) => {
        this.appObj = res.Result;
      });
    },
    openLink(type, item) {
      /**
       *       <label @click="openLink(7)">模拟考场</label>
            <label @click="openLink(6)">历年真题</label>
            <label @click="openLink(5)">章节练习</label>
            <label @click="openLink(10)">每日一练</label>
       */
      switch (type) {
        case 1:
          window.open(window.location.origin + "/header/coursetaste/coursetaste.html");
          break;
        case 2:
          window.open(window.location.origin + "/uc/register");
          break;
        case 3:
          window.open(this.advStatus.href);
          break;
        case 4:
          window.open(window.location.origin + item.Href);
          break;
        case 5: // 章节练习
          window.open(window.location.origin + `/center/study/tiku/chapter?domain=${this.curClass.ClassEName}`);
          break;
        case 6: // 历年真题
          window.open(window.location.origin + `/center/study/tiku/paper-past?domain=${this.curClass.ClassEName}`);
          break;
        case 7: // 模拟考试
          window.open(window.location.origin + `/center/study/tiku/paper-mock?domain=${this.curClass.ClassEName}`);
          break;
        case 8: {
          // if (this.isShowPrice) {
          window.location.href = window.location.origin + (this.seniorFlog ? "/fine" : "/norm") + "/category/currency/#/?class=" + this.curClass.ClassEName;
          // } else {
          //   window.core233.project.unionKefuDialog();
          // }
          break;
        }
        case 10: // 每日一练
          window.open(window.location.origin + `/center/study/tiku/daily?domain=${this.curClass.ClassEName}`);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/index.scss";
.course {
  width: 1200px;
  margin: 0 auto 40px;
  .top {
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ul {
      display: flex;
      li {
        list-style: none;
        color: #333;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        padding-bottom: 20px;
        margin-right: 72px;
        cursor: pointer;
      }
      .select {
        @include globe_color();
        @include course_border_bottom();
        font-size: 18px;
        font-weight: bold;
      }
    }
    .more {
      font-size: 16px;
      font-weight: 400;
      color: #333;
      line-height: 18px;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        vertical-align: top;
        margin-left: 7px;
        margin-top: 2px;
      }
    }
  }
  .bottom {
    margin-top: 31px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-wrap: wrap;
      width: 854px;
      height: 600px;
      .not-course {
        width: 100%;
        height: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -1px 16px 34px 4px rgba(180, 180, 180, 0.18);
      }
      .box {
        width: 270px;
        height: 289px;
        overflow: hidden;
        background: #fff;
        box-shadow: -1px 16px 34px 4px rgba(180, 180, 180, 0.18);
        margin-right: 20px;
        margin-bottom: 23px;
        padding-top: 26px;
        box-sizing: border-box;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-child(n + 4) {
          margin-bottom: 0;
        }
        .class {
          padding: 0 19px;
          p {
            color: #333;
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            margin-bottom: 16px;
            height: 46px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          span {
            color: #999;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            height: 14px;
            display: inline-block;
          }
          .teacher {
            margin: 19px 0 16px;
            display: flex;
            height: 62px;
            .item {
              width: 40px;
              text-align: center;
              margin-right: 12px;
              overflow: hidden;
              .img-box {
                overflow: hidden;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #f2f4f3;
                img {
                  max-width: 100%;
                  height: auto;
                  object-fit: cover;
                }
              }
              label {
                font-size: 12px;
                color: #999;
                font-weight: 400;
              }
            }
          }
        }
        .fun {
          @include course_list_background();
          height: 86px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 21px;
          box-sizing: border-box;
          .price {
            span {
              color: #e13b29;
              font-weight: 500;
              font-size: 18px;
              label {
                font-size: 22px;
              }
            }
            p {
              font-size: 14px;
              color: #999;
              font-weight: 400;
            }
          }
          .button {
            width: 106px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 20px;
            @include globe_color();
            @include globe_border();
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
              transform: translateY(-3px);
              border: none;
              @include globe_button_hover();
              @include globe_gradient();
            }
          }
        }
      }
    }
    .right {
      width: 330px;
      height: 600px;
      background: url(https://img2.233.com/wx/union/pc/home/rhombus-bg.jpg) no-repeat top right;
      background-color: #fff;
      box-shadow: -1px 15px 25px 3px rgba(180, 180, 180, 0.18);
      text-align: center;
      .count {
        padding: 0 14px;
        p {
          color: #343434;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          margin: 36px 0 12px;
          span {
            @include globe_color();
          }
        }
        .unconfirmed {
          font-size: 18px;
          font-weight: 400;
          color: #9a9a9a;
          line-height: 40px;
          padding-bottom: 26px;
          border-bottom: 1px dashed #eaeaea;
        }
        .num {
          border-bottom: 1px dashed #eaeaea;
          padding-bottom: 26px;
          label {
            width: 36px;
            height: 40px;
            line-height: 40px;
            background: #393939;
            border-radius: 3px;
            color: #fbfbfb;
            font-size: 33px;
            font-weight: 400;
            display: inline-block;
            margin-right: 5px;
          }
          span {
            margin-left: 5px;
            font-size: 21px;
            color: #343434;
            font-weight: 400;
            line-height: 40px;
          }
        }
      }
      .hot {
        padding: 24px 22px;
        .name {
          color: #343434;
          font-size: 26px;
          font-weight: 400;
          line-height: 36px;
          img {
            width: 21px;
            height: 28px;
            vertical-align: top;
            margin-right: 6px;
          }
        }
        .labels {
          margin: 7px 0 12px;
          display: flex;
          flex-wrap: wrap;
          label {
            color: #666;
            font-size: 15px;
            font-weight: 400;
            width: 80px;
            overflow: hidden;
            line-height: 35px;
            height: 35px;
            display: inline-block;
            width: 33.33%;
            cursor: pointer;
            &:hover {
              @include globe_color();
            }
          }
        }
        .activity {
          img {
            width: 100%;
            height: 100px;
          }
        }
      }
      .qrcode {
        border-top: 1px dashed #eaeaea;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 21px 7px 0 47px;
        box-sizing: border-box;
        .img {
          width: 113px;
          img {
            width: 113px;
            height: 113px;
          }
          p {
            color: #343434;
            font-size: 16px;
            font-weight: 400;
            margin-top: 4px;
            display: flex;
            justify-content: space-around;
            label {
              margin: 0 2px;
            }
          }
        }
        .labels {
          text-align: right;
          label {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #eee;
            color: #666;
            font-size: 14px;
            font-weight: 400;
            margin: 0 18px 18px 0;
            padding: 4px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
