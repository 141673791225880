<template>
  <h2>{{title}}</h2>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
h2{
  line-height: 108px;
  color: #343434;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}
</style>