<template>
  <div class="advantage">
    <div class="box">
      <div class="img-background">
        <img src="https://img2.233.com/wx/union/pc/home/blue/teacher-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/teacher-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/teacher-icon.png" v-else>
      </div>
      <h2>专业师资</h2>
      <p>业内老师，系统讲解大纲知识点，突出重难点，紧跟命题方向透彻解析教材</p>
    </div>
    <div class="box">
      <div class="img-background">
        <img src="https://img2.233.com/wx/union/pc/home/blue/exam-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/exam-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/exam-icon.png" v-else>
      </div>
      <h2>在线模考</h2>
      <p>海量全真模拟试题全面覆盖各类考点记录每次模考过程并汇总错题供反复演练</p>
    </div>
    <div class="box">
      <div class="img-background">
        <img src="https://img2.233.com/wx/union/pc/home/blue/download-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/download-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/download-icon.png" v-else>
      </div>
      <h2>免费下载</h2>
      <p>课程视频免费下载，配套课程讲义，方便越远越灵活安排学习时间</p>
    </div>
    <div class="box">
      <div class="img-background">
        <img src="https://img2.233.com/wx/union/pc/home/blue/iquality-icon.png" v-if="skinColor === 'blue'">
        <img src="https://img2.233.com/wx/union/pc/home/yellow/iquality-icon.png" v-else-if="skinColor === 'yellow'">
        <img src="https://img2.233.com/wx/union/pc/home/red/iquality-icon.png" v-else>
      </div>
      <h2>课程答疑</h2>
      <p>4-24小时老师在线答疑，让学习不留疑问</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advantage",
  props: {
    skinColor: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/index.scss";
.advantage{
  width: 1200px;
  margin: 39px auto 32px;
  display: flex;
  justify-content: space-between;
  .box{
    width: 285px;
    height: 321px;
    text-align: center;
    background: #fff;
    box-shadow: 0px -1px 35px 4px rgba(180, 180, 180, 0.18);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 40px 0 40px;
    box-sizing: border-box;
    transition: .2s;
    &:hover{
      transform: translateY(-3px);
    }
    .img-background{
      width: 69px;
      height: 69px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 14px 23px 3px rgba(175, 175, 175, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h2{
      margin-top: 15px;
      line-height: 50px;
      font-size: 23px;
      font-weight: 400;
      color: #333;
      position: relative;
      &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: calc(50% - 10px);
        width: 20px;
        height: 3px;
        @include globe_background();
      }
    }
    p{
      margin-top: 12px;
      line-height: 29px;
      color: #999;
      font-size: 16px;
      font-weight: 400;
      width: 204px;
    }
  }
}
</style>