<template>
  <div class="teacher">
    <div class="one">
      <div class="box">
        <div class="top">
          <img src="https://img2.233.com/wx/union/pc/home/professional-icon.png">
          <h4>专业功底扎实</h4>
        </div>
        <p>诸多老师毕业于985、211等高校，拥有过硬的学科知识，且教学经验丰富。他们是各领域集理论知识与实践经验于一体的专业老师，讲课主次分明，深受学员喜爱。</p>
      </div>
      <div class="box">
        <div class="top">
          <img src="https://img2.233.com/wx/union/pc/home/teaching-icon.png">
          <h4>教学经验丰富</h4>
        </div>
        <p>业内大咖老师，部分高达20年。他们深谙考试规律，授课经验丰富，个性鲜明，讲课逻辑思维严谨，不拖沓，直击考试，深受学员欢迎。</p>
      </div>
      <div class="box">
        <div class="top">
          <img src="https://img2.233.com/wx/union/pc/home/research-icon.png">
          <h4>对考试深入专研</h4>
        </div>
        <p>老师亲自报名参与考试，分析历年真题考点分布情况，提炼历年高频考点，总结各年度出题特点，预测当年命题趋势，帮助学员轻松通过考试。</p>
      </div>
      <div class="box">
        <div class="top">
          <img src="https://img2.233.com/wx/union/pc/home/two-teacher-icon.png">
          <h4>双师资授课模式</h4>
        </div>
        <p>部分考试每科聘请2位老师授课，更好地满足不同学员和不同阶段的学习需求，学员可根据个人喜好自由选择老师，高效备考复习。</p>
      </div>
    </div>
    <div class="two" @mouseover="enter" @mouseleave="leave">
      <swiper :options="swiperOptions" ref="teacherSwiper">
        <swiper-slide v-for="item in teacherList" :key="item.Id">
          <div class="box">
            <div class="resume">
              <div class="img-box">
                <img :src="item.PicPath">
              </div>
              <div class="info">
                <span>{{item.Name}}</span>
                <p :title="item.CourseType">主讲：{{item.CourseType}}</p>
              </div>
            </div>
            <div class="detail" v-html="item.Introduction" :title="item.Introduction"></div>
          </div>
          <img src="https://img2.233.com/wx/union/pc/home/blue/marks.png" class="marks" v-if="skinColor === 'blue'">
          <img src="https://img2.233.com/wx/union/pc/home/yellow/marks.png" class="marks" v-else-if="skinColor === 'yellow'">
          <img src="https://img2.233.com/wx/union/pc/home/red/marks.png" class="marks" v-else>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" v-if="teacherList.length > 3"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "Teacher",
  props: {
    teacherList: {
      type: Array,
      default: () => []
    },
    skinColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      swiperOptions: {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        slidesPerView: 3,
        slidesPerGroup: 3,
        autoplay: 3000,
        autoplayDisableOnInteraction : false
      }
    }
  },
  mounted() {
    if (this.teacherList.length < 4) {
      this.$refs.teacherSwiper.swiper.stopAutoplay()
    }
  },
  methods: {
    enter() {
      this.$refs.teacherSwiper.swiper.stopAutoplay()
    },
    leave() {
      this.$refs.teacherSwiper.swiper.startAutoplay()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/index.scss";
.teacher{
  width: 1200px;
  margin: 0 auto;
  .one{
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .box{
      width: 279px;
      height: 225px;
      background: rgba(0, 0, 0, .28);
      border-radius: 20px;
      padding: 30px 19px 0 22px;
      box-sizing: border-box;
      transition: .2s;
      &:hover{
        transform: translateY(-3px);
        background: rgba(0, 0, 0, .48);
      }
      .top{
        display: flex;
        align-items: center;
        h4{
          font-size: 22px;
          color: #fff;
          font-weight: 400;
          line-height: 32px;
          margin-left: 15px;
        }
      }
      p{
        color: #D0D7FF;
        font-size: 14px;
        font-weight: 400;
        margin-top: 20px;
        line-height: 24px;
      }
    }
  }
  .two{
    width: 100%;
    margin-top: 34px;
    background: #fff;
    box-shadow: -1px 16px 34px 4px rgba(180, 180, 180, 0.18);
    padding: 0 24px;
    box-sizing: border-box;
    margin-bottom: 30px;
    .swiper-slide{
      .marks{
        display: inline-block;
        width: 68px;
        height: 56px;
        position: absolute;
        top: 48px;
        left: 120px;
      }
      &:nth-child(3n), &:nth-child(3n - 1){
        .box{
          &::after{
            content: "";
            display: inline-block;
            width: 0;
            height: 232px;
            position: absolute;
            top: 85px;
            left: 0;
            border-left: 1px dashed rgba(67, 92, 237, .1);
          }
        }
      }
      .box{
        padding: 75px 40px 87px;
        box-sizing: border-box;
        .resume{
          display: flex;
          align-items: center;
          .img-box {
            overflow: hidden;
            width: 116px;
            height: 116px;
            border-radius: 58px;
            img{
              max-width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
          .info{
            margin-left: 36px;
            span{
              font-size: 26px;
              color: #333;
              font-weight: bold;
              line-height: 30px;
            }
            p{
              font-size: 16px;
              font-weight: 400;
              color: #999;
              line-height: 21px;
              margin-top: 18px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
        .detail{
          color: #555;
          font-size: 16px;
          font-weight: 400;
          line-height: 29px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin-top: 30px;
          height: 90px;
        }
      }
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
      bottom: 45px;
    }
    /deep/.swiper-pagination{
      .swiper-pagination-bullet{
        @include globe_background();
        opacity: .25;
      }
      .swiper-pagination-bullet-active{
        opacity: 1;
        width: 28px;
        border-radius: 4px;
      }
    }
  }
}
</style>