<template>
  <div class="adv">
    <h3 v-if="advStatus.flag === 1">新手有礼，注册即领1200元优惠券</h3>
    <h3 v-if="advStatus.flag === 2">新手有礼，扫码领好课</h3>
    <h3 v-if="advStatus.flag === 3">累计购课满300元，再购课享8.5折优惠</h3>
    <img src="https://img2.233.com/wx/union/pc/home/adv-img1.png" v-if="advStatus.flag === 1" @click="openLink(1, '/uc/register')">
    <img src="https://img2.233.com/wx/union/pc/home/adv-img2.png" v-if="advStatus.flag === 2" @click="openLink(2)">
    <img src="https://img2.233.com/wx/union/pc/home/adv-img3.png" v-if="advStatus.flag === 3" @click="openLink(1, '/header/coursetaste/coursetaste.html')">
  </div>
</template>

<script>
export default {
  name: "Adv",
  props: {
    advStatus: {
      type: Object,
      default: () => {},
    }
  },
  methods: {
    openLink(type, link) {
      if (type === 1) {
        window.open(window.location.origin + link)
      } else {
        window.open(this.advStatus.href)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.adv{
  width: 1200px;
  height: 155px;
  margin: 0 auto;
  background: url(https://img2.233.com/wx/union/pc/home/banner-bg.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 0 74px 0 302px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 18px;
  h3{
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    line-height: 155px;
    background-image: -webkit-linear-gradient(bottom, #ffbb70,#ffe8ce, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img{
    height: 80px;
    cursor: pointer;
    transition: .2s;
    &:hover{
      transform: translateY(-3px);
    }
  }
}
</style>